import * as React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulContactPage {
        title
        subtitle
        subtitleSpan
        map {
          name
          sourceLink {
            sourceLink
          }
        }
        address {
          option
        }
        emailAddress {
          name
          link
          label
        }
        phone {
          link
          label
          name
        }
        workHours {
          label
          name
        }
      }
    }
  `)
  const contactData = data.contentfulContactPage;
  const mapData = data.contentfulContactPage.map;
  const location = data.contentfulContactPage.address;
  const email = data.contentfulContactPage.emailAddress;
  const phoneNumber = data.contentfulContactPage.phone;
  const hours = data.contentfulContactPage.workHours;
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="map-section">
        <div className="contact-map-area">
          {mapData.map(({ name, sourceLink }, index) => (
            <iframe
              key={index}
              style={{ borderLeft: "5px solid #fe5f18" }}
              allowFullScreen
              aria-hidden="false"
              className="contact-map"
              title={name}
              src={sourceLink.sourceLink}
            />
          ))}
        </div>
      </div>
      <div className="contact-page-section">
        <div className="">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact-info-boxed">
                <div className="column mb-5">
                  <div className="title centered mb-3 text-white call">{contactData.title}</div>
                  <h2>Serrekunda, <span>The Gambia</span></h2>
                  <div className="text">Sait Matty Road, Bakau</div>
                  <div className="email">{email.name} <a href={email.link}>{email.label}</a></div>
                </div>
                <div className="column">
                  <div className="call">{phoneNumber.name}<br /><a href={phoneNumber.link}>{phoneNumber.label}</a></div>
                  <span className="call mb-3">Address:</span><br />
                  <ul className="location-list">
                    {location.map(({ option }, index) => (
                      <li key={index}>
                        {option}
                      </li>
                    ))}
                    <li><span>{hours.name}</span>{hours.label}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="form-boxed">
                <div className="sec-title centered">
                  <h2>{contactData.subtitle} <span>{contactData.subtitleSpan}</span></h2>
                </div>
                <div className="boxed-inner">
                  <div className="contact-form">
                    <form method="post" action="mail.php" id="contact-form">
                      <div className="row clearfix">

                        <div className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <input type="text" name="name" placeholder="Name *" required />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <input type="email" name="email" placeholder="Emaill Address *" required />
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 form-group">
                          <input type="text" name="subject" placeholder="Subject (Optional)" required />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea name="message" placeholder="Message" defaultValue={""} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center form-group">
                          <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="txt">Send Message</span></button>
                        </div>
                      </div>
                    </form>
                    <p className="form-messege" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
